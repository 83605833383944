import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "@lingui/macro";
import { Check, UploadSimple, Warning, ArrowLeft} from "@phosphor-icons/react";
import { UpdateUserDto, updateUserSchema } from "@rocket-resume/dto";
import {
  Button,
  buttonVariants,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@rocket-resume/ui";
import { cn } from "@rocket-resume/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { UserAvatar } from "@/client/components/user-avatar";
import { useToast } from "@/client/hooks/use-toast";
import { useResendVerificationEmail } from "@/client/services/auth";
import { useUploadImage } from "@/client/services/storage";
import { useUpdateUser, useUser } from "@/client/services/user";

type Props = {
  onCloseSection: () => void; 
};

export const AccountSettings = ({ onCloseSection }: Props) => {
  const { user } = useUser();
  const { toast } = useToast();
  const { updateUser, loading } = useUpdateUser();
  const { uploadImage, loading: isUploading } = useUploadImage();
  const { resendVerificationEmail } = useResendVerificationEmail();

  const inputRef = useRef<HTMLInputElement>(null);

  const form = useForm<UpdateUserDto>({
    resolver: zodResolver(updateUserSchema),
    defaultValues: {
      picture: "",
      name: "",
      username: "",
      email: "",
    },
  });

  useEffect(() => {
    user && onReset();
  }, [user]);

  const onReset = () => {
    if (!user) return;

    form.reset({
      picture: user.picture ?? "",
      name: user.name,
      username: user.username,
      email: user.email,
    });
  };

  const onSubmit = async (data: UpdateUserDto) => {
    if (!user) return;

    // Check if email has changed and display a toast message to confirm the email change
    if (user.email !== data.email) {
      toast({
        variant: "info",
        title: t`Check your email for the confirmation link to update your email address.`,
      });
    }

    await updateUser({
      name: data.name,
      email: data.email,
      picture: data.picture,
      username: data.username,
    });

    form.reset(data);
  };

  const onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const response = await uploadImage(file);
      const url = response.data;

      await updateUser({ picture: url });
    }
  };

  const onResendVerificationEmail = async () => {
    const data = await resendVerificationEmail();

    toast({ variant: "success", title: data.message });
  };

  if (!user) return null;

  return (
    <div className="space-y-6">
      <AccordionItem value="1">
        <AccordionTrigger>
          <div className="flex items-center gap-4">
            <ArrowLeft size={32} onClick={onCloseSection} className="cursor-pointer"/>
            <h3 className="text-2xl font-bold leading-relaxed tracking-tight">{t`Account`}</h3>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <Form {...form}>
            <form className="grid gap-6 sm:grid-cols-2" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                name="picture"
                control={form.control}
                render={({ field, fieldState: { error } }) => (
                  <div className={cn("flex items-end gap-x-4 sm:col-span-2", error && "items-center")}>
                    <UserAvatar />

                    <FormItem className="flex-1">
                      <FormLabel>{t`Picture`}</FormLabel>
                      <FormControl>
                        <Input placeholder="https://..." {...field} value={field.value ?? ""} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>

                    {!user.picture && (
                      <>
                        <input ref={inputRef} hidden type="file" onChange={onSelectImage} />

                        <motion.button
                          disabled={isUploading}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className={cn(buttonVariants({ size: "icon", variant: "ghost" }), "absolute")}
                          onClick={() => inputRef.current?.click()}
                        >
                          <UploadSimple />
                        </motion.button>
                      </>
                    )}
                  </div>
                )}
              />

              <FormField
                name="name"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t`Name`}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="username"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t`Username`}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="email"
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t`Email`}</FormLabel>
                    <FormControl>
                      <Input type="email" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />

              <div className={cn("flex items-center gap-x-1 font-medium text-lg opacity-100 relative pt-6 right-4",
                  user.emailVerified ? "text-success-accent" : "text-[#FFEB00]",
                )}>
                {user.emailVerified ? <Check size={20} /> : <Warning size={20} />}
                {user.emailVerified ? t`Verified` : t`Unverified`}
              </div>

              <div>
                {!user.emailVerified && (
                  <Button
                    variant="outline"
                    className="border border-[#FFEB00] text-[#FFEB00] text-sm p-3 bg-transparent"
                    onClick={onResendVerificationEmail}
                  >
                    Verify Email
                  </Button>
                )}
              </div>

              <AnimatePresence presenceAffectsLayout>
                {form.formState.isDirty && (
                  <motion.div
                    layout
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -10 }}
                    className="flex items-center space-x-2 self-center sm:col-start-2"
                  >
                    <Button type="submit" disabled={loading}>
                      {t`Save Changes`}
                    </Button>
                    <Button type="reset" variant="ghost" onClick={onReset}>
                      {t`Discard`}
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>
            </form>
          </Form>
        </AccordionContent>
      </AccordionItem>
    </div>
  );
};
